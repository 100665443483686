import React from "react"
import { Link } from "gatsby"
import LayoutMain from "components/layout/layoutMain"
import { mermaidUrl } from 'constants/urls';
import SEO from "components/Seo"
import Header from "components/Header"
import Footer from "components/Footer"

const SecondPage = () => (
  <LayoutMain>
    <SEO title="Header Footer" />
    <Header useReactApi={false} />
    <h1>Hello, Gatsby</h1>
    <p>Version 5</p>
    <p>MERMAID_HOST: {mermaidUrl}</p>
    <Link to="/">Go back to the homepage</Link>
    <Footer />

    <script src={`${mermaidUrl}/-/js/notice-bar.js`}></script>
    <script src={`${mermaidUrl}/-/js/global-navigation.js`}></script>
  </LayoutMain>
)

export default SecondPage
